@import '~antd/dist/antd.css';

body {
  overflow: hidden;
}

.padding {
  padding: 24px;
}

.loginInput {
  border: 1px solid #5f636b;
}

.inputCustomColors {
  border: 1px solid #f6a324;
}

.loginInput:hover,
.inputCustomColors:hover {
  border-color: #13e197;
}

.loginInput:focus-visible,
.inputCustomColors:focus-visible {
  outline: none;
}

.loginButton {
  width: 100%;
  height: 50px;
  border-radius: 6px;
  background-color: #13e197;
  color: #10111f;
  font-size: 18px;
  padding: 13px 16px;
  text-align: center;
  border: 1px solid #13e197;
  margin-top: 48px;
}

.loginButton:hover {
  color: #13e197;
  background-color: transparent;
}

.imgHeight img {
  height: 22px;
}

.formContainer {
  width: 418px;
}

.overviewName span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 458px) {
  .formContainer {
    width: 100%;
  }
}
